/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Popover, Table } from "antd";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentDetails, getTableData } from "../hooks/fetchHook";
import { AiOutlineEye, AiOutlineMore } from "react-icons/ai";
import { Restricted } from "../hooks/useRestricted";
import { openScreen } from "../actions/ui";

import { ExportData } from "../components/xlsx/ExportData";
import { ImportData } from "../components/xlsx/ImportData";
import {
  formatExport,
  formatImport,
  formatToTable,
} from "../helpers/columnsFormat";
import { FilterPanel } from "../components/ui/FilterPanel";
import { getEmptyFilters } from "../helpers/getEmptyFilters";

export const ListDocumentsTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const refreshKey = useSelector((state) => state.ui.refreshKey);
  const realm = useSelector((state) => state.auth.realm);

  const filterFields = [
    {
      id: "customerName",
      name: "Nombre de Cliente",
      type: "string",
    },
    {
      id: "taxReceiptNumber",
      name: "No. Comprobante Fiscal",
      type: "string",
    },
    {
      id: "vatId",
      name: "Cédula o RNC",
      type: "string",
    },
    {
      id: "device",
      name: "Dispositivo",
      type: "string",
    },
    {
      id: "created",
      name: "Fecha de creación",
      type: "date",
    },
  ]

  const [filters, setFilters] = useState(getEmptyFilters(filterFields));




  useEffect(() => {
    dispatch(getTableData("document", realm))
      .then((response) => {
        for (let prop of response) {
          prop.key = prop.remoteId;
          prop.actions = [ActionType.VIEW_DETAILS];
        }
        setData(response);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshKey, filters]);
  const ActionType = Object.freeze({
    VIEW_DETAILS: {
      label: "Ver detalles",
      icon: (
        <>
          <AiOutlineEye />
        </>
      ),
      handler(item) {
        dispatch(getDocumentDetails("document", item))
          .then(() => {
            dispatch(openScreen("uiOpenDocumentDetails"));
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  });
  const docTypes = [
    { label: "Conduce", value: 0 },
    { label: "Factura", value: 1 },
    { label: "Comprobante de Consumo", value: 2 },
    { label: "Transferencia de Inventario", value: 3 },
    { label: "Recibo", value: 4 },
    { label: "Pedido", value: 5 },
    { label: "Levantamiento", value: 6 },
  ];
  const columns = [
    {
      title: "Nombre del Cliente",
      width: 120,
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer - b.customer,
      render: (item) => {
        if (item === undefined) {
          return "NA";
        } else {
          return item.name;
        }
      },
    },
    {
      title: "Tipo de Documento",
      width: 130,
      dataIndex: "documentType",
      key: "documentType",
      sorter: (a, b) => a.documentType - b.documentType,
      render: (text) => {
        for (const prop of docTypes) {
          if (text === prop.value) {
            return <div>{prop.label}</div>;
          }
        }
      },
    },
    {
      title: "Concepto",
      width: 120,
      dataIndex: "concept",
      key: "concept",
      sorter: (a, b) => a.concept.length - b.concept.length,
      render: (text) => {
        if (text === 0) {
          return <div>Contado</div>;
        }
        if (text === 0) {
          return <div>Crédito</div>;
        }
      },
    },
    {
      title: "Numero de Comprobante Fiscal",
      width: 130,
      dataIndex: "taxReceiptNumber",
      key: "taxReceiptNumber",
      sorter: (a, b) => a.taxReceiptNumber.length - b.taxReceiptNumber.length,
    },
    {
      title: "Cédula o RNC",
      width: 140,
      dataIndex: "vatId",
      key: "vatId",
      sorter: (a, b) => a.vatId.length - b.vatId.length,
    },

    {
      title: "Número",
      width: 100,
      dataIndex: "number",
      key: "number",
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: "Ordinal",
      width: 90,
      dataIndex: "ordinal",
      key: "ordinal",
      sorter: (a, b) => a.ordinal - b.ordinal,
    },
    {
      title: "Secuencia global",
      width: 110,
      dataIndex: "globalSequence",
      key: "globalSequence",
      sorter: (a, b) => a.globalSequence - b.globalSequence,
    },
    {
      title: "Subtotal",
      width: 120,
      dataIndex: "subTotal",
      key: "subTotal",
      sorter: (a, b) => a.subTotal - b.subTotal,
    },
    {
      title: "Monto",
      width: 120,
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Monto de Impuestos",
      width: 120,
      dataIndex: "taxAmount",
      key: "taxAmount",
      sorter: (a, b) => a.taxAmount - b.taxAmount,
    },
    {
      title: "Monto de Pago",
      width: 120,
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      sorter: (a, b) => a.paymentAmount - b.paymentAmount,
    },
    {
      title: "Dispositivo",
      width: 140,
      dataIndex: "device",
      key: "device",
      sorter: (a, b) => a.device - b.device,
      render: (item) => {
        if (item === undefined) {
          return "NA";
        } else {
          return item.description;
        }
      },
    },
    {
      title: "Nombre del Usuario",
      width: 120,
      dataIndex: "user",
      key: "user",
      sorter: (a, b) => a.user - b.user,
      render: (item) => {
        if (item === undefined) {
          return "NA";
        } else {
          return item.name;
        }
      },
    },
    {
      title: "Fecha de creación",
      width: 120,
      dataIndex: "created",
      key: "created",
      render: (a) => { 
        const date = new Date(a);
        const formattedDate = date.toLocaleDateString();  
        const formattedTime = date.toLocaleTimeString();  
        return <p>{`${formattedDate} ${formattedTime}`}</p>;
      }
    },

    {
      key: "actions",
      width: 70,
      render(actionList, row) {
        return (
          <Restricted permission={"Document"}>
            <Popover
              className="_more-btn"
              content={
                <>
                  {actionList.actions.map((definition, index) => (
                    <div
                      key={index}
                      className="_ops-option"
                      onClick={() => definition.handler(row)}
                    >
                      {definition.icon} {definition.label}
                    </div>
                  ))}
                </>
              }
            >
              {
                <Button>
                  <AiOutlineMore size="20" />
                </Button>
              }
            </Popover>
          </Restricted>
        );
      },
    },
  ];

  return (
    <div>
      <FilterPanel
        setFilters={setFilters}
        filterFields={filterFields}
      />
      <Table
        className="_Pointer"
        onRow={(record) => {
          return {
            onClick: (event) => {
              dispatch(getDocumentDetails("document", record))
                .then(() => {
                  dispatch(openScreen("uiOpenDocumentDetails"));
                })
                .catch((err) => {
                  console.log(err);
                });
            }, // click row
          };
        }}
        locale={{
          triggerAsc: "Click para ordernar ascendente",
          triggerDesc: "Click para ordernar descendente",
          cancelSort: "Click para cancelar orden",
        }}
        onChange={() => formatToTable(columns)}
        dataSource={data.filter((c) => {
          if (c.customer) {
            if (
              c.customer.name.toLowerCase().includes(filters.customerName.toLowerCase()) &&
              c.vatId.toLowerCase().includes(filters.vatId.toLowerCase()) &&
              c.device.description.toLowerCase().includes(filters.device.toLowerCase()) &&
              (c.created.includes(filters.created)) &&
              c.taxReceiptNumber.toLowerCase().includes(filters.taxReceiptNumber.toLowerCase())
              
            ) {
              return c;
            }
          }
          return null;
        })}
        columns={formatToTable(columns)}
        scroll={{ x: 400 }}
        footer={() => {
          return (
            <>
              {/* <ImportData columns={formatImport(columns)} tableName={'document'} /> */}
              <ExportData
                columns={formatExport(columns)}
                data={data}
                tableName={"document"}
              />
            </>
          );
        }}
      />
    </div>
  );
};
